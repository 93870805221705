<script>
export default {
  data() {
    return {}
  },
  render() {
    return <router-view />
  }
}
</script>
