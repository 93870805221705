import { deviceEnquire, DEVICE_TYPE } from '@/utils/device'
import { mapState } from 'vuex'
import { pyFilter } from '@/utils/antd'
import { formatObject } from '@/utils/format'
import treeUtils from '@/utils/treeUtils'
const mixinDevice = {
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  methods: {
    isMobile() {
      return this.device === DEVICE_TYPE.MOBILE
    },
    isDesktop() {
      return this.device === DEVICE_TYPE.DESKTOP
    },
    isTablet() {
      return this.device === DEVICE_TYPE.TABLET
    },
  },
}

const AppDeviceEnquire = {
  mounted() {
    const { $store } = this
    deviceEnquire((deviceType) => {
      console.log(deviceType)
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:
          $store.commit('TOGGLE_DEVICE', 'desktop')
          break

        case DEVICE_TYPE.LAPTOP:
          $store.commit('TOGGLE_DEVICE', 'laptop')
          break

        case DEVICE_TYPE.TABLET:
          $store.commit('TOGGLE_DEVICE', 'tablet')
          break

        case DEVICE_TYPE.MOBILE:
        default:
          $store.commit('TOGGLE_DEVICE', 'mobile')
          break
      }
    })
  },
}
const toolMixin = {
  computed: {
    modalFormLayout() {
      return {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      }
    },
  },
  methods: {
    // 安全的设置form包含的fields(设置不包含的fileds会导致警告)
    safeSetFieldsValue(form, data) {
      this.$nextTick(() => {
        const fields = form.getFieldsValue()
        let initData = {}
        for (let i in fields) {
          if (i in data) {
            initData[i] = data[i]
          }
        }
        form.setFieldsValue(initData)
        for (const i in form.instances) {
          let f = form.instances[i]
          if (f.$options._componentTag === 'PictureCardListUpload') {
            f.resetFileList()
          }
        }
      })
    },
    translateType(value, typeName, typesMap = this.typesMap || {}) {
      let label = ''
      try {
        const mapIncludesCollection = {
          ...typesMap,
        }
        const collectionMap = this.$store.state.collectionStore.collectionMap
        for (const i in collectionMap) {
          mapIncludesCollection[i] = collectionMap[i].list
        }
        const type = mapIncludesCollection[typeName].find((v) => String(v.value) === String(value))
        if (type) {
          label = type.label
        }
      } catch (e) {
        console.error(e)
      }
      return label
    },
    filterByPY(input, option) {
      const label = option.componentOptions.children[0]?.text || ''
      return pyFilter(input, label)
    },
    // 转换options 为label
    labelOptions(options) {
      return options.map((o) => {
        return o.label
      })
    },
    formatParams: formatObject,
    // exactTime: 是否选择的确切时间点（range-picker showTime 则需要设置exactTime）。未选择确切时间,开始取00:00:00，结束取23:59:59
    formatTimeRangeParams(
      params,
      rangeKey,
      { format = 'YYYY-MM-DD HH:mm:ss', exactTime = false, keys = ['', ''] } = {}
    ) {
      return this.formatParams(params, (key, value) => {
        if (key === rangeKey) {
          if (value) {
            const Upper1stChar = key.charAt(0).toUpperCase() + key.slice(1)
            const rangeStart = {
              key: keys[0] || `start${Upper1stChar}`,
              value: value[0] ? (exactTime ? value[0].format(format) : value[0].startOf('day').format(format)) : '',
            }
            const rangeEnd = {
              key: keys[1] || `end${Upper1stChar}`,
              value: value[1] ? (exactTime ? value[1].format(format) : value[1].endOf('day').format(format)) : '',
            }
            return [rangeStart, rangeEnd]
          }
        }
        return { key, value }
      })
    },
    resetModal(modalName = 'modal') {
      this.$reset(modalName)
    },
    resetForm(form) {
      const resetFields = []
      for (const i in form.instances) {
        let f = form.instances[i]
        // a-select allowClear的才清除,其他的都清除。
        if (f.$options._componentTag === 'a-select') {
          if (f.allowClear) {
            resetFields.push(i)
          }
        } else {
          resetFields.push(i)
        }
      }
      form.resetFields(resetFields)
    },
    $reset(field) {
      if (field) {
        this[field] = this.$options.data.call(this)[field]
      } else {
        Object.assign(this.$data, this.$options.data.call(this))
      }
    },
    $useRowIndex(row, index) {
      return index
    },
  },
}
// 虽然很方便，但是谨慎用，清楚使用时机！最重要的原则，尽量不在mixin中定义变量
const queryViewMixin = {
  /**
   *  使用这个queryView 必须定义的东西
   *  data:{ form, pageNo, pageSize }
   * */
  mixins: [toolMixin],
  methods: {
    // pagination是单独处理的；所以tableChange 只处理sorter 和filter
    tableChange(pagination, filters, sorter) {
      const sortColumn = treeUtils.find(this.tableColumns, (column) => {
        // 要排序的项必须设置dataIndex
        return column.dataIndex === sorter.columnKey
      })
      if (sortColumn) {
        this.tableColumns = treeUtils.map(this.tableColumns, (column) => {
          if (column.dataIndex === sortColumn.dataIndex) {
            column.sortOrder = sorter.order
          } else {
            column.sortOrder = false
          }
          return column
        })
        // if (sortColumn.sortType === 'server') {
        this.doQuery()
        // }
      }
    },
    pageChange(page, pageSize) {
      this.pageSize = pageSize
      this.pageNo = page
      this.doQuery()
    },
    getSortParams() {
      const sortColumn = treeUtils.find(this.tableColumns, (column) => column.sortOrder)
      let sortField = ''
      let sortOrder = ''
      if (sortColumn) {
        sortField = sortColumn.dataIndex
        sortOrder = sortColumn.sortOrder || ''
      }
      return {
        sortField,
        sortOrder: sortOrder.slice(0, -3),
      }
    },
    // 默认会转换moment 为YYYY-MM-DD （可以不使用这个，在doQuery中自行组装参数）
    getPageParams(pageNo, pageSize) {
      const params = this.form.getFieldsValue()
      const sortParams = this.getSortParams()
      return {
        pageNo,
        pageSize,
        ...params,
        ...sortParams,
      }
    },
    doQuery() {
      console.warn('should implement')
    },
    resetAndQuery() {
      this.resetForm(this.form)
      this.doQuery(1)
    },
    resetTable() {
      this.pageNo = 1
      this.totalCount = 0
      this.dataSource = []
    },
    getSelectedRowKeys() {
      if (!this.selectedRowKeys.length) {
        this.$error({ title: '请选择要操作的行' })
        return Promise.reject(new Error('E_NO_SELECT'))
      } else {
        return this.selectedRowKeys
      }
    },
    checkBatchActionRows(rows = this.selectedRows) {
      if (!rows.length) {
        this.$error({ title: '请选择要操作的行' })
        return Promise.reject(new Error('E_NO_SELECT'))
      } else {
        return rows
      }
    },
  },
}

export { AppDeviceEnquire, mixinDevice, queryViewMixin, toolMixin }
