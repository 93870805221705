// eslint-disable-next-line
import { BasicLayout, MainLayout, RouteView } from '@/layouts'
import treeUtils from '@/utils/treeUtils'

// /m 表示角色权限菜单，用户有权限，才会展示其中的某些菜单
export const permissionMenuRoutes = [
  {
    path: '/m/system/userManage',
    meta: {
      title: '用户管理',
    },
    component: () => import('@/pages/system/userManage/AdminUserManage.vue'),
  },
  {
    path: '/m/system/menuManage',
    meta: {
      title: '菜单管理',
    },
    component: () => import('@/pages/system/menuManage/MenuManage.vue'),
  },
  {
    path: '/m/system/roleManage',
    meta: {
      title: '角色管理',
    },
    component: () => import('@/pages/system/roleManage/RoleManage.vue'),
  },
  {
    path: '/m/system/behaviorLog',
    meta: {
      title: '用户行为日志',
    },
    component: () => import('@/pages/system/BehaviorLog.vue'),
  },
  {
    path: '/m/scopeMain/crewAudit',
    meta: {
      title: '班组入驻审核',
    },
    component: () => import('@/pages/scopes/main/crewAudit/CrewAudit.vue'),
  },
  {
    path: '/m/scopeMain/attendanceManage',
    meta: {
      title: '考勤班组变更',
    },
    component: () => import('@/pages/scopes/main/attendanceManage/AttendanceManage.vue'),
  },
  {
    path: '/m/scopeMain/eAttendanceAudit',
    meta: {
      title: '考勤异常审核',
    },
    component: () => import('@/pages/scopes/main/EAttendanceAudit.vue'),
  },
  {
    path: '/m/scopeMain/addWorkerAudit',
    meta: {
      title: '项目添员审核',
    },
    component: () => import('@/pages/scopes/main/AddWorkerAudit.vue'),
  },
  {
    path: '/m/scopeMain/eDeal',
    meta: {
      title: '异常处理',
    },
    component: () => import('@/pages/scopes/main/EDeal.vue'),
  },
  {
    path: '/m/fastRepair/user',
    meta: {
      title: '人员角色',
    },
    component: () => import('@/pages/scopes/fastRepair/user/User.vue'),
  },
  {
    path: '/m/fastRepair/projectManage',
    meta: {
      title: '项目人员',
    },
    component: () => import('@/pages/scopes/fastRepair/projectManage/ProjectManage.vue'),
  },
  {
    path: '/m/fastRepair/areaManage',
    meta: {
      title: '区域人员',
    },
    component: () => import('@/pages/scopes/fastRepair/AreaManage.vue'),
  },
  {
    path: '/m/fastRepair/blocUser',
    meta: {
      title: '集团人员',
    },
    component: () => import('@/pages/scopes/fastRepair/BlocUser.vue'),
  },
  {
    path: '/m/attendance/attendanceList',
    meta: {
      title: '考勤明细',
    },
    component: () => import('@/pages/scopes/main/AttendanceList'),
  },
  {
    path: '/m/attendance/attendanceDay',
    meta: {
      title: '考勤日汇总',
    },
    component: () => import('@/pages/scopes/main/attendanceDay/AttendanceDay'),
  },
  {
    path: '/m/attendance/attendanceMonth',
    meta: {
      title: '考勤月汇总',
    },
    component: () => import('@/pages/scopes/main/attendanceMonth/AttendanceMonth'),
  },
  {
    path: '/m/attendance/attendanceLockList',
    meta: {
      title: '考勤锁定明细列表',
    },
    component: () => import('@/pages/scopes/main/AttendanceLockList'),
  },
  {
    path: '/m/attendance/projectWorker',
    meta: {
      title: '项目工人',
    },
    component: () => import('@/pages/scopes/main/projectWorker/ProjectWorker'),
  },
  {
    path: '/m/attendance/projectLeader',
    meta: {
      title: '项目班组',
    },
    component: () => import('@/pages/scopes/main/ProjectLeader'),
  },
  {
    path: '/m/attendance/attendanceCheckResult',
    meta: {
      title: '考勤明细核对',
    },
    component: () => import('@/pages/scopes/main/AttendanceCheckResult'),
  },
  {
    path: '/m/attendance/attendanceDevice',
    meta: {
      title: '异常考勤设备',
    },
    component: () => import('@/pages/scopes/main/AttendanceDevice'),
  },
  {
    path: '/m/attendance/deviceCheckList',
    meta: {
      title: '设备入库台账',
    },
    component: () => import('@/pages/scopes/main/DeviceCheckList'),
  },
  {
    path: '/m/unify/menuManage',
    meta: {
      title: '统一门户菜单',
    },
    component: () => import('@/pages/scopes/unify/MenuManage'),
  },
  {
    path: '/m/report/fee',
    meta: {
      title: '管理费用填报',
    },
    component: () => import('@/pages/scopes/report/Fee'),
  },
  {
    path: '/m/report/regionNum',
    meta: {
      title: '区域人数',
    },
    component: () => import('@/pages/scopes/report/RegionNum'),
  },
  {
    path: '/m/report/projectNum',
    meta: {
      title: '项目人数',
    },
    component: () => import('@/pages/scopes/report/ProjectNum'),
  },
  {
    path: '/m/unify/demandList',
    meta: {
      title: '需求列表',
    },
    component: () => import('@/pages/scopes/unify/DemandList'),
  },
  {
    path: '/m/labor/evidence/workerList',
    meta: {
      title: '工人列表',
    },
    redirect: '/m/labor/evidence/workerList/list',
    children: [
      {
        path: '/m/labor/evidence/workerList/list',
        meta: {
          title: '工人列表',
          keepAlive: true,
        },
        component: () => import('@/pages/scopes/labor/evidence/workerList/WorkerList'),
      },
      {
        path: '/m/labor/evidence/workerList/detail',
        meta: {
          title: '工人详情',
        },
        component: () => import('@/pages/scopes/labor/evidence/workerDetail/WorkerDetail'),
      },
    ],
  },
  {
    path: '/m/aie/rule',
    meta: {
      title: '规则管理',
    },
    redirect: '/m/aie/rule/ruleList',
    children: [
      {
        path: '/m/aie/rule/ruleList',
        meta: {
          title: '规则列表',
        },
        component: () => import('@/pages/scopes/aie/rule/RuleList'),
      },
      {
        path: '/m/aie/rule/ruleDetail',
        meta: {
          title: '规则详情',
        },
        component: () => import('@/pages/scopes/aie/rule/RuleDetail'),
      },
    ],
  },
  {
    path: '/m/aie/device',
    meta: {
      title: '设备管理',
    },
    redirect: '/m/aie/device/deviceList',
    children: [
      {
        path: '/m/aie/device/deviceList',
        meta: {
          title: '设备列表',
        },
        component: () => import('@/pages/scopes/aie/device/DeviceList'),
      },
    ],
  },
  {
    path: '/m/aie/carRecord',
    meta: {
      title: '过车记录',
    },
    redirect: '/m/aie/carRecord/carRecordList',
    children: [
      {
        path: '/m/aie/carRecord/carRecordList',
        meta: {
          title: '过车记录列表',
        },
        component: () => import('@/pages/scopes/aie/carRecord/CarRecordList'),
      },
    ],
  },
  {
    path: '/m/aie/carRecordNum',
    meta: {
      title: '过车记录统计',
    },
    component: () => import('@/pages/scopes/aie/carRecordNum/CarRecordNum'),
  },
  {
    path: '/m/aie/risk',
    meta: {
      title: '风险事件',
    },
    redirect: '/m/aie/risk/riskList',
    children: [
      {
        path: '/m/aie/risk/riskList',
        meta: {
          title: '风险事件列表',
        },
        component: () => import('@/pages/scopes/aie/risk/RiskList'),
      },
    ],
  },
  {
    path: '/m/aie/riskSpotCheck',
    meta: {
      title: '风险处理抽检',
    },
    component: () => import('@/pages/scopes/aie/risk/SpotCheck'),
  },
  {
    path: '/m/aie/appVersion',
    meta: {
      title: 'app版本',
    },
    redirect: '/m/aie/appVersion/appVersion',
    children: [
      {
        path: '/m/aie/appVersion/appVersion',
        meta: {
          title: 'app版本',
        },
        component: () => import('@/pages/scopes/aie/appVersion/AppVersion'),
      },
    ],
  },
  {
    path: '/m/aie/message/exceptionProject',
    meta: {
      title: '关闭项目通知',
    },
    component: () => import('@/pages/scopes/aie/message/ExceptionProject'),
  },
  {
    path: '/m/aie/outRecordList',
    meta: {
      title: '出场检查记录',
    },
    component: () => import('@/pages/scopes/aie/carRecord/OutRecordList'),
  },
  {
    path: '/m/opsServer/opsLineManage',
    meta: {
      title: '条线管理',
    },
    component: () => import('@/pages/scopes/opsServer/OpsLineManage'),
  },
]
// /m 代表 权限路由 - 菜单视图
// /b 代表 权限路由 - 空白视图
export const asyncRouterMapRoot = [
  {
    path: '/m',
    component: MainLayout,
    // 动态添加
    children: [],
  },
  {
    path: '/b',
    component: BasicLayout,
    // 动态添加
    children: [],
  },
  // 后面两个需要获取菜单后，动态添加重定向
  // path:'/'，定向到菜单匹配的第一个子元素菜单
  // path:'*'，重定向到404，注意这个的位置应该是最后

  // {
  //   path: '/',
  //   redirect: ''
  // },

  // {
  //   path: '*',
  //   redirect: '/404'
  // }
]
// 任意角色的用户都可见
export const processedDefaultMenus = [
  // {
  //   path: '/m/default1',
  //   meta: {
  //     title: '测试',
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/default1/l1',
  //   children: [
  //     {
  //       path: '/m/default1/l1',
  //       meta: {
  //         title: '测试',
  //       },
  //       component: () => import('@/pages/system/menuManage/MenuManage.vue'),
  //     },
  //     {
  //       path: '/m/default1/l2',
  //       meta: {
  //         title: 'cs2',
  //       },
  //       component: () => import('@/pages/system/roleManage/RoleManage.vue'),
  //     },
  //     {
  //       path: '/m/default1/l3',
  //       meta: {
  //         title: 'cd',
  //       },
  //       component: () => import('@/pages/system/userManage/AdminUserManage.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/m/d3',
  //   meta: {
  //     title: '工作台',
  //     order: 2,
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/workplace',
  //   children: [
  //     {
  //       path: '/m/workplace',
  //       meta: {
  //         title: '工作台',
  //         hidden: true,
  //       },
  //       component: () => import('@/pages/Workplace'),
  //     },
  //   ],
  // },
  // {
  //   path: '/m/d1',
  //   meta: {
  //     title: '静态目录1',
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/d1/l1',
  //   children: [
  //     {
  //       path: '/m/d1/l1',
  //       meta: {
  //         title: '系统管理',
  //       },
  //       component: RouteView,
  //       redirect: '/m/home',
  //       children: [
  //         {
  //           path: '/m/home',
  //           meta: {
  //             title: '首页',
  //           },
  //           component: () => import('@/pages/Home'),
  //         },
  //         // {
  //         //   path: '/m/menuManage',
  //         //   component: () => import('@/pages/MenuManage'),
  //         //   meta: {
  //         //     title: '菜单管理'
  //         //   }
  //         // },
  //         // {
  //         //   path: '/m/roleManage',
  //         //   component: () => import('@/pages/roleManage/RoleManage'),
  //         //   meta: {
  //         //     title: '角色管理'
  //         //   }
  //         // }
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: '/m/d2',
  //   meta: {
  //     title: '静态目录2',
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/d2/l1',
  //   children: [
  //     {
  //       path: '/m/d2/l1',
  //       meta: {
  //         title: '简单模块',
  //         // hideChildrenInMenu: true
  //       },
  //       component: RouteView,
  //       redirect: '/m/simplePage/main',
  //       children: [
  //         {
  //           path: '/m/simplePage/main',
  //           meta: {
  //             title: '主模块（列表页）',
  //           },
  //           component: RouteView,
  //           redirect: '/m/simplePage/main/list',
  //           children: [
  //             {
  //               path: '/m/simplePage/main/list',
  //               meta: {
  //                 title: '列表页',
  //               },
  //               component: () => import('@/pages/simplePage/main/List'),
  //             },
  //             {
  //               path: '/m/simplePage/main/detail',
  //               meta: {
  //                 title: '详情页',
  //               },
  //               component: () => import('@/pages/simplePage/main/Detail'),
  //             },
  //           ],
  //         },
  //         {
  //           path: '/m/simplePage/overview',
  //           meta: {
  //             title: '其他模块',
  //           },
  //           component: () => import('@/pages/simplePage/Overview'),
  //         },
  //       ],
  //     },
  //     {
  //       path: '/m/d2/l2',
  //       meta: {
  //         title: 'keepAlive模块',
  //         // hideChildrenInMenu: true
  //       },
  //       component: RouteView,
  //       redirect: '/m/keepAlivePage/main',
  //       children: [
  //         {
  //           path: '/m/keepAlivePage/main',
  //           meta: {
  //             title: '主模块（列表页）',
  //           },
  //           component: RouteView,
  //           redirect: '/m/keepAlivePage/main/list',
  //           children: [
  //             {
  //               path: '/m/keepAlivePage/main/list',
  //               meta: {
  //                 title: '列表页',
  //                 keepAlive: true,
  //               },
  //               component: () => import('@/pages/keepAlivePage/main/List'),
  //             },
  //             {
  //               path: '/m/keepAlivePage/main/detail',
  //               meta: {
  //                 title: '详情页',
  //               },
  //               component: () => import('@/pages/keepAlivePage/main/Detail'),
  //             },
  //           ],
  //         },
  //         {
  //           path: '/m/keepAlivePage/overview',
  //           meta: {
  //             title: '其他模块',
  //           },
  //           component: () => import('@/pages/keepAlivePage/Overview'),
  //         },
  //       ],
  //     },
  //   ],
  // },
]
export const constantRoutes = [
  {
    path: '/user',
    component: RouteView,
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/pages/user/Login'),
      },
    ],
  },
  {
    path: '/oaSso',
    component: RouteView,
    children: [
      {
        path: 'loginStart',
        meta: {
          title: 'oa登陆',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/oaSso/LoginStart.vue'),
      },
      {
        path: 'loginEnd',
        meta: {
          title: 'oa登陆',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/oaSso/LoginEnd.vue'),
      },

      {
        path: 'redirect/:path*',
        meta: {
          title: 'oa重定向',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/oaSso/Redirect.vue'),
      },

      {
        path: 'bridge',
        meta: {
          title: 'oa单点',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/oaSso/Bridge.vue'),
      },
    ],
  },
  {
    path: '/sso',
    component: () => import(/* webpackChunkName: "init" */ '@/pages/Sso'),
  },
  {
    path: '/umsSso',
    component: () => import(/* webpackChunkName: "init" */ '@/pages/UmsSso'),
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/exception/404'),
  },
  {
    path: '/403',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/exception/403'),
  },
]

treeUtils.forEach(constantRoutes, (route) => {
  if (!route.meta) {
    route.meta = {}
  }
  route.meta.isAuth = false
})
