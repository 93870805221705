import Vue from 'vue'
import VueRouter from 'vue-router'
import moment from 'moment'
import 'moment/locale/zh-cn'
import NProgress from 'nprogress' // progress bar

import watermark from 'watermark-dom'
import '@/core/component_use'
import '@/assets/css/index.less'

import App from './App.vue'

import store from './store'
import '@/core/directives'
import router from './router'

import axiosInstance from './plugins/axios'
import permissionWrapper from './plugins/permission'
import popErrorPageWrapper from './plugins/popErrorPage'
import keepAliveCtrlWrapper from './plugins/keepAliveCtrl'
import collectionWrapper from './plugins/collection'

Vue.config.productionTip = false

Vue.use(VueRouter)

moment.locale('zh-cn')

Vue.prototype.$watermark = watermark
Vue.prototype.$progress = NProgress
Vue.prototype.$axios = axiosInstance
Vue.prototype.$popErrorPage = popErrorPageWrapper({ router, store })
Vue.prototype.$routex = keepAliveCtrlWrapper({ router, store })
Vue.prototype.$collection = collectionWrapper({ router, store, app: Vue.prototype })

permissionWrapper({ router, store, app: Vue.prototype })
export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
