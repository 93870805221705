<template>
  <a-pagination
    class="queryView-pagination"
    v-if="totalCount"
    :total="totalCount"
    :current="pageNo"
    :pageSize="pageSize"
    :showTotal="showTotalFn ? showTotalFn : null"
    :size="size"
    @change="onChange"
    @showSizeChange="onChange"
    showSizeChanger
    showQuickJumper
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default() {
        return 'middle'
      },
    },
    showTotalFn: {
      type: Function,
      default: (total, range) => {
        return `共${total}条数据`
      },
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    pageNo: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {}
  },
  methods: {
    onChange(pageNo, pageSize) {
      this.$emit('update:pageNo', pageNo)
      this.$emit('update:pageSize', pageSize)
      this.$emit('change', pageNo, pageSize)
    },
  },
}
</script>
<style lang='less' scoped>
/deep/ .ant-pagination-total-text {
  float: none;
  margin-right: 16px;
}
/deep/ .ant-pagination-options {
  float: none;
  margin-left: 16px;
}
</style>
