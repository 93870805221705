<template>
  <a-layout :class="['layout', device]" :style="{ paddingLeft: 0, height: '100vh', minWidth: '1280px' }">
    <global-s-header :device="device" :menus="menus" />
    <a-layout-content>
      <a-layout :class="['layout-horizontal']" :style="{ paddingLeft: 0, height: 'calc(100vh - 64px)' }">
        <!-- layout content -->
        <side-s-menu :menus="menus"></side-s-menu>
        <a-layout-content class="content-main-holder-wrapper">
          <!-- <multi-tab v-if="multiTab"></multi-tab> -->
          <div class="content-main-holder" id="watermarkParent">
            <a-layout class="content-main-holder-content">
              <a-layout-content :style="{ margin: '16px' }">
                <div class="content-main" :style="{ minHeight: 'calc(100vh - 96px)' }">
                  <transition name="page-transition">
                    <KeepAliveRouterView />
                  </transition>
                </div>
              </a-layout-content>
            </a-layout>
          </div>
        </a-layout-content>

        <!-- layout footer -->
      </a-layout>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import { mixinDevice } from '@/utils/mixin'
import KeepAliveRouterView from '@/components/KeepAliveRouterView'

import RouteView from './RouteView'
import SideSMenu from '@/components/SideSMenu'
import GlobalSHeader from '@/components/GlobalSHeader'

export default {
  name: 'BasicLayout',
  mixins: [mixinDevice],
  components: {
    RouteView,
    SideSMenu,
    GlobalSHeader,
    KeepAliveRouterView,
  },
  data() {
    return {
      menus: [],
    }
  },
  computed: {
    ...mapState({
      permissionMenus: (state) => state.permission.menus,
      nickname: (state) => state.user.nickname,
      phone: (state) => state.user.phone,
    }),
  },
  created() {
    this.menus = this.permissionMenus
  },
  mounted() {
    this.$watermark.init({
      watermark_txt: `${this.nickname} ${this.phone}`,
      watermark_width: 160,
      watermark_alpha: 0.1,
      watermark_fontsize: '12px',
      watermark_parent_node: 'watermarkParent',
      watermark_x: -80, // 水印起始位置x轴坐标
      watermark_y: -20,
      watermark_x_space: 50, // 水印x轴间隔
      watermark_y_space: 75,
    })

    // this.$axios.post('/watermark/common/getOne', {
    //   fileUrl,
    // })
  },
}
</script>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */
.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  transition: opacity 0.5s;
}
</style>
