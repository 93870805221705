<template>
  <div class="pop-error-page">
    <div class="exception">
      <div class="imgBlock">
        <div class="imgEle"></div>
      </div>
      <div class="content">
        <h1>500</h1>
        <div class="desc">{{ msg }}</div>
        <div class="actions">
          <a-button type="primary" @click="refresh" v-if="actions.refresh">刷新重试</a-button>
          <a-button @click="logout" v-if="actions.login">重新登陆</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      msg: '',
      actions: {
        refresh: true,
        login: true,
      },
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['Logout']),
    show(params = {}) {
      this.msg = params.message || '500 Server Error'
      if (params.actions) {
        this.actions = params.actions
      }
    },
    hide() {
      if (document.body.contains(this.$el)) {
        document.body.removeChild(this.$el)
      }
    },
    refresh() {
      window.location.reload()
    },
    logout() {
      this.Logout({ type: 'reLaunch' }).catch((err) => {
        this.$message.error({
          title: '错误',
          description: err.message,
        })
      })
    },
  },
}
</script>

<style lang="less">
.pop-error-page {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  .exception {
    display: flex;
    align-items: center;
    height: 80%;
    min-height: 500px;

    .imgBlock {
      flex: 0 0 62.5%;
      width: 62.5%;
      padding-right: 152px;
      zoom: 1;
      &::before,
      &::after {
        content: ' ';
        display: table;
      }
      &::after {
        clear: both;
        height: 0;
        font-size: 0;
        visibility: hidden;
      }
    }

    .imgEle {
      float: right;
      width: 100%;
      max-width: 430px;
      height: 360px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      background-image: url('https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg');
    }

    .content {
      flex: auto;

      h1 {
        margin-bottom: 24px;
        color: #434e59;
        font-weight: 600;
        font-size: 72px;
        line-height: 72px;
      }

      .desc {
        margin-bottom: 16px;
        color: @text-color-secondary;
        font-size: 20px;
        line-height: 28px;
      }

      .actions {
        button:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  @media screen and (max-width: @screen-xl) {
    .exception {
      .imgBlock {
        padding-right: 88px;
      }
    }
  }

  @media screen and (max-width: @screen-sm) {
    .exception {
      display: block;
      text-align: center;
      .imgBlock {
        margin: 0 auto 24px;
        padding-right: 0;
      }
    }
  }

  @media screen and (max-width: @screen-xs) {
    .exception {
      .imgBlock {
        margin-bottom: -24px;
        overflow: hidden;
      }
    }
  }
}
</style>
