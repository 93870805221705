import Vue from 'vue'
// 这个方法不做本地储存，作用就是能够快速获取到kv
export default function(context) {
  const { store, app, router } = context
  const { $axios } = app
  const loading = {}
  const mutations = {
    ADD_COLLECTION(state, { name, list, storageType }) {
      const collectionMap = {
        ...state.collectionMap,
        [name]: {
          storageType: storageType,
          list,
        },
      }
      state.collectionMap = collectionMap
    },
    RESET_COLLECTION(state, name) {
      let collectionMap = {}
      if (name) {
        collectionMap = JSON.parse(JSON.stringify(state.collectionMap))
        delete collectionMap[name]
      }

      state.collectionMap = collectionMap
    },
  }
  const actions = {
    // 没统一出口数据格式！！！
    async GetCollection(
      { state, commit },
      name,
      options = {
        storageType: 'route',
        forceUpdate: true,
      }
    ) {
      if (loading[name]) {
        const res = await loading[name]
        return res.data
      }
      if (!options.forceUpdate) {
        //
        if (state.collectionMap[name]) {
          return state.collectionMap[name]
        }
      }
      const fn = _name => {
        let p
        if (_name.match('KX.')) {
          const code = _name.split('KX.')[1]
          p = $axios
            .post('/kx/usercenter/admin/common/getKvByType', {
              type: code,
            })
            .then(res => {
              return res.data.map(item => {
                return {
                  label: item.value,
                  value: item.key,
                }
              })
            })
        }
        return p
      }
      loading[name] = fn(name)
      if (!loading[name]) {
        return []
      }
      try {
        const result = await loading[name]
        delete loading[name]
        commit('ADD_COLLECTION', {
          name,
          list: result,
          storageType: options.storageType,
        })
        return result
      } catch (e) {
        console.log(e)
        return e
      }
    },
  }
  store.registerModule('collectionStore', {
    // namespace: false,
    state: {
      collectionMap: {},
    },
    mutations: mutations,
    actions: actions,
  })
  router.afterEach(() => {
    store.commit('RESET_COLLECTION')
  })
  Vue.mixin({
    computed: {
      $collection() {
        return function(collection) {
          const collectionMap = this.$store.state.collectionStore.collectionMap
          const c = collectionMap[collection]
          if (c) {
            return c.list
          }
          if (!loading[collection]) {
            // 通过store的改变触发视图上$collection值改变
            this.$store.dispatch('GetCollection', collection)
          }
          return []
        }
      },
    },
  })
}
