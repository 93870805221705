import axiosInstance from '@/plugins/axios'
// 需要mock的，需要把地址暴露，并在mock模块引用
export const SYSTEM_API_LOGIN = '/ums/weblogin/loginByPwd'
export const SYSTEM_API_REFRESHTOKEN = '/ums/weblogin/refreshToken'
export const SYSTEM_API_GET_AUTH = '/ums/webhome/webAuths/authCode'

export const login = async ({ username, password, code }) => {
  const res = await axiosInstance.post(SYSTEM_API_LOGIN, {
    loginname: username,
    password: password,
  })
  return res.data
}

export const doRefreshToken = async ({ refreshToken }) => {
  const res = await axiosInstance.post(SYSTEM_API_REFRESHTOKEN, { refreshToken })
  return res.data
}

export const getInfo = async () => {
  // 这个接口401时，单独处理
  const res = await axiosInstance.post(
    SYSTEM_API_GET_AUTH,
    {
      authCode: 'WEB',
    },
    {
      interceptors: {
        error: false,
      },
    }
  )
  return {
    roles: {
      permission: {
        menus: res.data,
      },
    },
  }
}
