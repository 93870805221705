import moment from 'moment'

export const getMonthOptions = (months = 6) => {
  const result = []
  for (let i = 0; i < months; i++) {
    const month = moment().subtract(i, 'month').format('YYYY-MM')
    result.push({
      label: month,
      value: month,
    })
  }
  return result
}
export const groupIdCard = (rule, value, cb) => {
  const idCards = value.split(',')
  for (let i = 0; i < idCards.length; i++) {
    const idCard = idCards[i].trim()
    if (idCard && !idCard.match(/^(\d{15}|\d{18}|\d{17}(\d|X|x))+$/)) {
      cb(new Error('身份证格式有误'))
      return
    }
  }
  cb()
}
export const isVehicleNumber = (rule, value, cb) => {
  if (
    !value.match(
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z0-9]{5,7}[挂学警港澳]{0,1}$/
    )
  ) {
    cb(new Error('车牌号格式错误'))
    return
  }
  cb()
}
export const pyFilter = (input, label) => {
  if (!label) {
    return false
  }
  const labelFirstLetter = window.pinyinUtil.getFirstLetter(label, true)
  if (label.toUpperCase().includes(input.toUpperCase())) {
    return true
  }
  for (let i = 0; i < labelFirstLetter.length; i++) {
    if (labelFirstLetter[i].includes(input.toUpperCase())) {
      return true
    }
  }
  return false
}
export const toSelectOptions = (list, { labelKey = 'label', valueKey = 'value' } = {}) => {
  const optionsList = []
  const repeatList = []
  function getRepeatIndex(label) {
    for (let i = 0; i < optionsList.length; i++) {
      const orginLabel = optionsList[i].origin[labelKey]
      if (orginLabel === label) {
        return i
      }
    }
    return -1
  }
  list.map((item, index) => {
    const label = item[labelKey]
    const value = item[valueKey]
    const repeatIndex = getRepeatIndex(label)
    const isLabelRepeat = repeatIndex > -1
    // 重复了
    if (isLabelRepeat) {
      repeatList.push(repeatIndex)
      repeatList.push(index)
    }
    optionsList.push({
      label,
      value,
      key: label + value,
      origin: item,
      isLabelRepeat,
    })
  })
  const warnMessage = []
  Array.from(new Set(repeatList)).map((indexInOptionsList) => {
    const label = optionsList[indexInOptionsList].origin[labelKey]
    const value = optionsList[indexInOptionsList].origin[valueKey]
    warnMessage.push(`repeat index ${indexInOptionsList},${label},${value}`)
    optionsList[indexInOptionsList].label = `${label} ${value}`
  })
  if (warnMessage.length) {
    console.warn(warnMessage)
  }
  return optionsList
}
