/* eslint-disable */
/**
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 *
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue'
import './antd'
import './fragment'
import './viewer'
import AutoImg from '@/components/AppAutoImg'
import RenderDom from '@/components/RenderDom'
import PictureCardUpload from '@/components/pictureCardUpload/PictureCardUpload'
import BasePagination from '@/components/BasePagination'

Vue.component('auto-img', AutoImg)
Vue.component('picture-card-upload', PictureCardUpload)
Vue.component('base-pagination', BasePagination)
Vue.component('render-dom', RenderDom)
