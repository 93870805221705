import _ from 'lodash'
import config from '@/config'
import store from '@/store'
import { app } from '@/main'
import md5 from 'crypto-js/md5'
function doSign(signStr) {
  return md5(signStr).toString()
}
// 对传入的data转换成排序过后的json对象
// 会对数组排序，并忽略对象中为undefined和null的值
function getSorted(data) {
  if (_.isArray(data)) {
    const out = []
    data.map((item, index) => {
      const sorted = getSorted(item)
      out.push(sorted)
    })
    out.sort()
    return '[' + out.join(',') + ']'
  } else if (_.isObject(data)) {
    const keys = Object.keys(data).sort()
    const out = []
    keys.map((key) => {
      const value = data[key]
      // undefined 或 null 跳过
      if (!(value === undefined || value === null)) {
        out.push(JSON.stringify(key) + ':' + getSorted(value))
      }
    })
    return '{' + out.join(',') + '}'
  } else {
    return JSON.stringify(data)
  }
}
function getSignHeaders(data) {
  const { appId, secretKey } = config.signConfig
  const timestamp = new Date().getTime()
  const source = 'web'
  // data为null 或 undefined时，直接视作用{}转换
  const sortedData = getSorted(data ? _.cloneDeep(data) : {})
  const signStr = `${appId}|${timestamp}|${source}|${sortedData}${secretKey}`
  // console.log(signStr)
  return {
    appId,
    timestamp,
    source,
    signature: doSign(signStr),
  }
}
export const getConfig = function () {
  return {
    authType: '',
  }
}
/** request相关 */

export const getBaseUrl = function () {
  return `${config.baseURL}`
}

export const getAuth = function () {
  const { accessToken, refreshToken } = store.state.user.auth
  return {
    accessToken,
    refreshToken,
  }
}
/** 错误展示/进度条/loading */

export const showError = function (axiosError) {
  // 请求取消，和错误已处理 两种状态，不需要再显示
  if (axiosError.code === 'E_HAS_RESOLVED' || axiosError.code === 'E_REQUEST_CANCEL') {
  } else {
    app.$message.error(axiosError.message)
  }
}

export const showLoading = function () {
  // app.$loading.show()
}
export const hideLoading = function () {
  // app.$loading.hide()
}

export const showProgress = function () {
  app.$progress.start()
}
// success  true表示 请求正常并进度条结束。false 表示请求异常并进度条结束
export const hideProgress = function (success = true) {
  app.$progress.done()
}
/** response相关 */

export const validResponse = function (res) {
  return res.data.code === '00000'
}
// 根据resData展示错误信息
export const getErrorMessage = function (res) {
  return res.data ? res.data.message : ''
}

/** 额外处理，一般不用 */

export const extraRequestHandle = function (req) {
  const { accessToken } = getAuth()
  const extraHeader = {
    ...getSignHeaders(req.data),
    token: accessToken,
    Authorization: accessToken,
  }

  for (const i in extraHeader) {
    req.headers[i] = extraHeader[i]
  }
  return req
}
export const extraResponseHandle = function (res) {
  return res
}
export const extraErrorHandle = function (error) {
  const details = {
    message: error.message,
  }
  for (const i in error) {
    details[i] = error[i]
  }
  console.info('ERROR', details)
  return error
}
/** 刷新token和登出相关 */

export const is401 = function (error) {
  // 网关校验的code和业务系统校验的code
  return error.data?.code === 'GW002' || error.data?.code === 'A0230'
}
export const doRefreshToken = function () {
  // RefreshToken需要完成token的更新
  return store.dispatch('RefreshToken')
}
export const doLogout = _.throttle(
  function (e) {
    let message = 'token已过期，请重新登陆'
    if (e.exceptionCode === 'E_REFRESH_FAIL') {
      message = '刷新token失败，请重新登陆'
    }
    if (e.exceptionCode === 'E_NO_REFRESHTOKEN') {
      // message = ''
    }
    showError({ message })
    return store.dispatch('Logout')
  },
  1000,
  { trailing: false }
)
