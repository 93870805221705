<template>
  <div class="auto-img" @dblclick="onDblclick">
    <BaseAutoImg
      :src="src"
      :fit="fit"
      style="width: 100%; height: 100%; min-height: inherit"
      @load="onLoad"
      @error="onError"
    >
      <template #error>
        <div class="auto-img-error" title="加载图片失败">
          <svg class="auto-img-error-text" viewBox="0 0 85 20">
            <text x="0" y="16" fill="#666">{{ src ? '加载图片失败' : '没有对应图片' }}</text>
          </svg>
        </div>
      </template>
      <template #loading><a-icon type="loading"></a-icon></template>
    </BaseAutoImg>
    <div v-if="loaded && preview" class="toolbar-preview" @click.stop="doPreview(preview)">
      <div class="preview-icon">
        <a-icon type="eye" class="cursor-pointer"></a-icon>
      </div>
    </div>
  </div>
</template>

<script>
import BaseAutoImg from './AutoImg/index.vue'
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from 'v-viewer'
export default {
  components: {
    BaseAutoImg,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: '',
    },
    preview: {
      type: [Boolean, Object],
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  methods: {
    onDblclick() {
      if (this.preview) {
        this.doPreview(this.preview)
      }
    },
    onLoad() {
      this.loaded = true
    },
    onError() {
      this.loaded = false
    },
    doPreview(preview) {
      if (typeof preview === 'boolean') {
        viewerApi({
          options: {
            inline: false,
            button: true,
            navbar: true,
            title: true,
            toolbar: {
              zoomIn: 1,
              zoomOut: 1,
              oneToOne: 1,
              reset: 1,
              // 单张图片，禁用播放、前、后
              prev: 0,
              play: 0,
              next: 0,
              rotateLeft: 1,
              rotateRight: 1,
              flipHorizontal: 1,
              flipVertical: 1,
            },
            tooltip: true,
            movable: true,
            zoomable: true,
            rotatable: true,
            scalable: true,
            transition: true,
            fullscreen: true,
            keyboard: true,
          },
          images: [this.src],
        })
      } else {
        viewerApi(preview)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.auto-img {
  position: relative;
  display: inline-block;
  overflow: hidden;
  .toolbar-preview {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    background: #000000;
    opacity: 0.7;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .auto-img-error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .auto-img-error-text {
      width: 80%;
      max-width: 100px;
      pointer-events: none;
    }
  }
}
</style>
