<script>
import treeUtils from '@/utils/treeUtils'
export default {
  name: 'SideMenu',
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeKeys: [],
      openKeys: [],
    }
  },
  computed: {
    level2Menus() {
      const level2Menus = this.menus[this.activeLevel1MenusIndex]
      return (level2Menus && level2Menus.children) || []
    },
    activeLevel1MenusIndex() {
      let index = -1
      for (let i = 0; i < this.menus.length; i++) {
        if (this.isMenuActive(this.menus[i])) {
          index = i
          break
        }
      }
      return index
    },
  },
  mounted() {
    this.updateMenu()
  },
  watch: {
    $route: function () {
      this.updateMenu()
    },
  },
  methods: {
    isMenuActive(item) {
      let flag = false
      const path = this.$route.path
      function wlk(menu) {
        if (path === menu.path) {
          flag = true
        } else {
          if (menu.children) {
            menu.children.map((m) => {
              wlk(m)
            })
          }
        }
      }
      wlk(item)
      return flag
    },
    updateMenu() {
      const activeRoutes = treeUtils.findAllParents(this.menus, (m) => {
        return m.path === this.$route.path
      })
      activeRoutes.shift()
      const routes = []
      activeRoutes.map((route) => {
        routes.push({
          meta: route.meta,
          path: route.path,
        })
      })
      routes.push({
        path: this.$route.path,
        meta: this.$route.meta,
      })
      this.activeKeys = routes.map((route) => {
        return route.path
      })
    },

    renderMenuItem(menu) {
      const isActive = this.activeKeys.includes(menu.path)
      const onClick = (e) => {
        this.$routex.reloadNext()
        this.$router.push(menu.path)
        e.preventDefault()
      }
      if (!menu.meta.hidden) {
        return (
          <div class={['menu-item', isActive ? 'active' : '']}>
            <span onClick={onClick}>
              {this.renderIcon(menu.meta.icon)}
              <span class="title-text">{menu.meta.title}</span>
            </span>
          </div>
        )
      }
    },
    renderRootMenuItem(menu) {
      let isActive = this.activeKeys.includes(menu.path)

      const affixMenuItems = []

      if (menu.children) {
        this.activeKeys.map((key) => {
          for (let i = 0; i < menu.children.length; i++) {
            if (key.startsWith(menu.children[i].path)) {
              isActive = true
            }
          }
        })
        for (let i = 0; i < menu.children.length; i++) {
          if (!menu.children[i].meta.hidden && menu.children[i].meta.type) {
            affixMenuItems.push(this.renderMenuItem(menu.children[i]))
          }
        }
      }
      const onClick = (e) => {
        this.$routex.reloadNext()
        this.$router.push(menu.path)
        e.preventDefault()
      }
      if (!menu.meta.hidden) {
        return (
          <div
            class={['menu-level-0', isActive ? 'active' : '', affixMenuItems.length ? 'has-children' : '']}
            ref={menu.path}
          >
            <div
              class="title"
              onClick={(e) => {
                console.log('l2menu click')
                if (!(isActive && this.$route.path === menu.path)) {
                  onClick(e)
                }
              }}
            >
              {this.renderIcon(menu.meta.icon)}
              <span class="title-text">{menu.meta.title}</span>
            </div>
            {affixMenuItems.length > 0 && <div class="affix-menu-list">{affixMenuItems}</div>}
          </div>
        )
      }
    },
    generateMenuTree(menu) {
      if (!menu.length) {
        return []
      }
      const rootMenuTree = []
      menu.map((m) => {
        const rootMenuItem = this.renderRootMenuItem(m)
        if (rootMenuItem) {
          rootMenuTree.push(rootMenuItem)
        }
      })
      return rootMenuTree
    },
    renderIcon(icon) {
      if (!icon || icon === 'none') {
        return null
      }
      const props = {}
      typeof icon === 'object' ? (props.component = icon) : (props.type = icon)
      return <a-icon {...{ props }} />
    },
  },

  render() {
    const menuTree = this.generateMenuTree(this.level2Menus)
    if (!menuTree.length) {
      return null
    }
    return (
      <div class="site-menu" style="width:200px" ref="menu">
        {menuTree}
      </div>
    )
  },
}
</script>
<style lang="less">
.site-menu {
  overflow: auto;
  background: #eaedf1;
  .menu-level-0 {
    color: #3a3a3a;
    position: relative;
    &.has-children {
      .title {
        padding-left: 36px;
        font-size: 16px;
        line-height: 55px;
        position: relative;
        border-bottom: 1px solid #e4e4e4;
        background: #f6f7fa;
        .title-text {
          margin-left: 12px;
        }
      }
    }
    &.active:not(.has-children) {
      > .title {
        color: @primary-color;
        &:before {
          background: @primary-color;
        }
      }
    }
    &:not(.has-children) > .title {
      line-height: 55px;
      padding-left: 36px;
      font-size: 16px;
      color: #333;
      cursor: pointer;
    }

    .affix-menu-list {
      background: #eaedf1;

      .menu-item {
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 50px;
        cursor: pointer;
        &.active {
          color: @primary-color;
          &:before {
            background: @primary-color;
          }
        }
        a {
          position: relative;
          color: #666;
          &:before {
            content: '';
            position: absolute;
            left: -8px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: #666;
          }

          &:hover {
            color: @primary-color;
          }
        }
      }
    }
  }
  > .menu-item {
    padding: 0 30px;
    line-height: 55px;
  }
}
</style>
