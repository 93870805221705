<template>
  <div class="base-auto-img">
    <img
      :src="src"
      class="base-auto-img__img"
      :style="{
        objectFit: fit,
      }"
      @load="onLoad"
      @error="onError"
    />
    <div v-if="loading" class="base-auto-img__loading">
      <slot name="loading"></slot>
    </div>
    <div v-if="error" class="base-auto-img__error">
      <slot name="error"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      error: false,
    }
  },
  watch: {
    src: {
      handler(value) {
        if (value) {
          this.loading = true
          this.error = false
        } else {
          this.loading = false
          this.error = true
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    onLoad() {
      this.loading = false
      this.$emit('load')
    },
    onError() {
      this.loading = false
      this.error = true
      this.$emit('error')
    },
  },
}
</script>
<style scoped lang="less">
.base-auto-img {
  position: relative;
  display: inline-block;
  .base-auto-img__img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .base-auto-img__error,
  .base-auto-img__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #969799;
    font-size: 14px;
    background-color: #f7f8fa;
  }
}
</style>
