<template>
  <a-popover
    v-model="visible"
    trigger="click"
    placement="bottomRight"
    overlayClassName="menu-user-avatar-pop"
    :getPopupContainer="() => $refs.avatarWrapper.parentElement"
    :autoAdjustOverflow="true"
    :arrowPointAtCenter="true"
    :overlayStyle="{ width: '300px' }"
  >
    <template slot="content">
      <a-spin :spinning="loading">
        <div class="app-info-menu">
          <div class="app-info-menu-item user-info" @click="openUserInfo">
            <div class="menu-label">
              <a-icon type="user"></a-icon>
              <span class="label-text">用户信息</span>
            </div>
            <div class="menu-content">
              <a-icon type="right"></a-icon>
            </div>
          </div>
          <div class="app-info-menu-item">
            <div class="menu-label">
              <a-icon type="setting"></a-icon>
              <span class="label-text">版本信息</span>
            </div>
            <div class="menu-content">V0.0.1</div>
          </div>
        </div>
      </a-spin>
    </template>
    <span @click="openAppInfo" class="avatar" ref="avatarWrapper">
      <a-avatar>{{ nickname.slice(nickname.length - 2, nickname.length) }}</a-avatar>
    </span>
  </a-popover>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HeaderNotice',
  data() {
    return {
      loading: false,
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['avatar', 'nickname']),
  },
  methods: {
    openAppInfo() {
      // if (!this.visible) {
      //   this.loading = true
      //   setTimeout(() => {
      //     this.loading = false
      //   }, 2000)
      // } else {
      //   this.loading = false
      // }
      this.visible = !this.visible
    },
    openUserInfo() {
      this.visible = false
      this.$router.push('/w/account/settings/base')
    },
  },
}
</script>

<style lang="css">
.menu-user-avatar-pop {
  top: 54px !important;
}
</style>
<style lang="less" scoped>
.nickname {
  margin-left: 6px;
}
.app-info-menu {
  margin: -8px 0;
  .app-info-menu-item {
    &.user-info {
      cursor: pointer;
    }
    height: 42px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    &:last-child {
      border-bottom: 0;
    }
    .menu-label {
      color: #333;
      .anticon {
        color: @primary-color;
        margin-right: 13px;
        font-size: 18px;
        vertical-align: middle;
      }
      .label-text {
        vertical-align: middle;
      }
    }
    .menu-content {
      color: #999;
    }
  }
}
.avatar {
  margin-right: 26px;
  vertical-align: top;
  display: inline-block;
  .ant-avatar {
    margin-top: -4px;
  }
}
</style>
