<template>
  <div class="header-animat">
    <a-layout-header :style="{ padding: '0' }">
      <div class="header">
        <h2 class="admin-title">{{ title }}</h2>
        <div class="top-menu">
          <div
            class="top-menu-item"
            v-for="(item, index) in menus"
            :key="index"
            @click="menuClick(item)"
            :class="{ active: activeLevel1MenusIndex === index }"
          >
            {{ item.meta.title }}
          </div>
        </div>
        <div class="header-extra" v-if="device === 'desktop'">
          <!-- <div class="weather action">
            <div class="weather-img">
              <img :src="weather.posimg" />
            </div>
            <div class="weather-info">
              <div class="w-row">
                <span class="w-area">{{ weather.city }}</span
                >:
                <span class="w-desc">{{ weather.desc }}</span>
              </div>
              <div class="w-row">
                <span class="w-detail">{{ weather.detail }}</span>
              </div>
            </div>
          </div> -->
          <div class="action">
            <menu-user-avatar />
            <span @click="showNotice">
              <a-badge :count="noticeCount" :number-style="{ backgroundColor: '#FF4D4F' }">
                <a-icon type="mail" class="action-icon"></a-icon>
              </a-badge>
              <span class="action-text">消息</span>
            </span>
          </div>
          <div class="help action">
            <a-icon type="question-circle" class="action-icon"></a-icon>
            <span class="action-text">帮助</span>
          </div>
          <div class="logout action" @click="handleLogout">
            <!-- <a-icon type="question-circle-o"></a-icon> -->
            <a-icon type="poweroff" class="action-icon"></a-icon>
            <span class="action-text">退出</span>
          </div>
        </div>
        <div class="header-extra collapse" v-else>
          <div class="action">
            <menu-user-avatar />
            <a-dropdown>
              <div class="collapse-btn">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
              <a-menu slot="overlay" class="user-dropdown-menu">
                <!-- <a-menu-item key="0">
                  <div class="weather action">
                    <div class="weather-img">
                      <img :src="weather.erpimg" />
                    </div>
                    <div class="weather-info">
                      <div class="w-row">
                        <span class="w-area">{{ weather.city }}</span
                        >:
                        <span class="w-desc">{{ weather.desc }}</span>
                      </div>
                      <div class="w-row">
                        <span class="w-detail">{{ weather.detail }}</span>
                      </div>
                    </div>
                  </div>
                </a-menu-item>
                <a-menu-divider /> -->
                <!-- <a-menu-item key="1" @click="showNotice">
                  <div class="action">
                    <a-badge :count="noticeCount" :number-style="{ backgroundColor: '#FF4D4F' }">
                      <a-icon type="mail" class="action-icon"></a-icon>
                    </a-badge>
                    <span class="action-text">消息</span>
                  </div>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="2">
                  <div class="action">
                    <a-icon type="question-circle" class="action-icon"></a-icon>
                    <span class="action-text">帮助</span>
                  </div>
                </a-menu-item>
                <a-menu-divider /> -->
                <a-menu-item key="3" @click="handleLogout">
                  <div class="action">
                    <a-icon type="poweroff" class="action-icon"></a-icon>
                    <span class="action-text">退出</span>
                  </div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </div>
    </a-layout-header>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MenuUserAvatar from '@/components/MenuUserAvatar'
export default {
  name: 'GlobalSHeader',
  computed: {
    activeLevel1MenusIndex() {
      let index = -1
      for (let i = 0; i < this.menus.length; i++) {
        if (this.isMenuActive(this.menus[i])) {
          index = i
          break
        }
      }
      return index
    },
    title() {
      return process.env.VUE_APP_SITE_TITLE
    },
  },
  components: {
    MenuUserAvatar,
  },
  data() {
    return {
      weather: {
        city: '',
        desc: '',
        img: '',
        detail: '',
      },
      noticeCount: 0,
    }
  },

  props: {
    menus: {
      type: Array,
      required: true,
    },
    device: {
      type: String,
      required: false,
      default: 'desktop',
    },
  },
  async mounted() {
    // this.getWeather()
    // this.getNoticeCount()
  },
  methods: {
    ...mapActions(['Logout']),
    menuClick(item) {
      this.$routex.reloadNext()
      this.$router.push(item.path)
    },

    isMenuActive(item) {
      let flag = false
      const path = this.$route.path
      function wlk(menu) {
        if (path === menu.path) {
          flag = true
        } else {
          if (menu.children) {
            menu.children.map((m) => {
              wlk(m)
            })
          }
        }
      }
      wlk(item)
      return flag
    },
    handleLogout() {
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk: () => {
          this.$store.commit('RESET_PERMISSION')
          return this.Logout({ type: 'reLaunch' }).catch((err) => {
            this.$message.error({
              title: '错误',
              description: err.message,
            })
          })
        },
        onCancel() {},
      })
    },
    // 显示通知公告
    showNotice() {
      console.log('显示通知公告')
      this.$router.push('/w/notice/userNoticeList')
    },
    async getNoticeCount() {
      // const res = await this.$axios.get('/erp/notice/getCurrentUserNoticeConunt')
      // const data = res.data
      // this.noticeCount = data
    },
    async getWeather() {
      // const res = await this.$axios.post('/erp/weather/listData')
      // const { city, casts } = res.data.forecasts[0]
      // const cast = casts[0]
      // const weather = {
      //   city: city,
      //   desc: cast.dayweather,
      //   posimg: cast.posimg,
      //   erpimg: cast.erpimg,
      //   detail: `${cast.nighttemp}℃~${cast.daytemp}℃`,
      // }
      // this.weather = weather
    },
  },
}
</script>

<style lang="less" scoped>
.company-name {
  font-size: 14px;
  display: flex;
  padding-top: 8px;
  padding-left: 4px;
  .company-name-main {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.header-animat {
  height: 64px;
}
.header {
  height: 100%;
  padding: 0 16px 0 0;
  background: @primary-color;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.header-extra {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  &.collapse {
    // .action {
    //   .avatar {
    //     margin-right: 8px;
    //   }
    // }
    .collapse-btn {
      display: inline-flex;
      height: 64px;
      vertical-align: top;
      align-items: center;
      padding: 0 16px;
      margin-left: -16px;
      margin-right: -16px;
      .dot {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #fff;
        margin: 0 2px;
      }
    }
  }
  .action {
    cursor: pointer;
    padding: 0 20px;
    transition: all 0.3s;
    font-size: 16px;
    height: 100%;
    color: #fff;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 20px;
      top: 20px;
      width: 1px;
      background: #bababa;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    // &:hover {
    //   background: rgba(0, 0, 0, 0.025);
    // }
    .action-icon {
      font-size: 20px;
    }
    .action-text {
      margin-left: 16px;
    }
    &.weather {
      display: inline-flex;
      .weather-info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
      }
      .weather-img {
        // filter: brightness(0);
        opacity: 0.7;
      }
      .w-row {
        line-height: 1;
        margin: 3px 0;
        font-size: 14px;
      }
    }
  }
}
.admin-title {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  font-size: 20px;
  margin: 0;
  line-height: 1;
  color: #fff;
  cursor: pointer;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.header-animat {
  position: relative;
  z-index: 11;
}
.top-menu {
  padding-left: 6px;
  display: flex;
  margin-left: 200px;
  color: #fff;
  font-size: 16px;
}
.top-menu-item {
  padding: 0 10px;
  cursor: pointer;
  &.active {
    background: #789bff;
  }
  &:hover:not(.active) {
    opacity: 0.7;
  }
}
</style>
<style lang="less">
.ant-dropdown {
  .user-dropdown-menu {
    margin-top: -4px;
    padding: 8px 20px;
    .ant-dropdown-menu-item {
      width: 170px;
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background: #fff;
      opacity: 0.7;
    }
    .action {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 14px;
      .action-icon {
        font-size: 18px;
      }
      .action-text {
        margin-left: 15px;
      }
      &.weather {
        display: inline-flex;
        font-size: 12px;
        .weather-info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: center;
        }
        .weather-img {
          filter: brightness(0);
          opacity: 0.7;
        }
        .w-row {
          line-height: 1;
          margin: 3px 0;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
