<template>
  <a-layout :class="['layout', device]" :style="{ paddingLeft: 0, height: '100vh' }">
    <a-layout-content class="content-main-holder-wrapper">
      <div class="content-main-holder">
        <a-layout class="content-main-holder-content">
          <a-layout-content>
            <div class="content-main" :style="{ minHeight: '100vh' }">
              <transition name="page-transition">
                <KeepAliveRouterView />
              </transition>
            </div>
          </a-layout-content>
        </a-layout>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import { mixinDevice } from '@/utils/mixin'
import KeepAliveRouterView from '@/components/KeepAliveRouterView'

import RouteView from './RouteView'
import SideSMenu from '@/components/SideSMenu'
import GlobalSHeader from '@/components/GlobalSHeader'

export default {
  name: 'BasicLayout',
  mixins: [mixinDevice],
  components: {
    RouteView,
    SideSMenu,
    GlobalSHeader,
    KeepAliveRouterView,
  },
  data() {
    return {
      menus: [],
    }
  },
  computed: {
    ...mapState({
      permissionMenus: (state) => state.permission.menus,
    }),
  },
  created() {
    this.menus = this.permissionMenus
  },
  mounted() {},
}
</script>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */
.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  transition: opacity 0.5s;
}
</style>
