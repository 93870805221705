import router, { resetAsyncRoutes } from '@/router'
import { login, doRefreshToken, getInfo } from '@/service/api/systemApi'
import { parseJwt } from '@/service/jwt'
const initUserState = {
  // 认证信息
  auth: {
    accessToken: '',
    refreshToken: '',
  },
  // 用户数据
  nickname: '',
  phone: '',
}
const user = {
  state: initUserState,
  mutations: {
    SET_TOKEN: (state, { accessToken, refreshToken }) => {
      state.auth.accessToken = accessToken
      state.auth.refreshToken = refreshToken
    },
    SET_USER(state, user) {
      for (const i in user) {
        if (i in state) {
          state[i] = user[i]
        }
      }
    },
    RESET_USER(state) {
      for (const i in initUserState) {
        state[i] = initUserState[i]
      }
      for (const j in state) {
        if (!(j in initUserState)) {
          delete state[j]
        }
      }
    },
  },

  actions: {
    // 登录
    async Login({ commit }, loginParams) {
      return new Promise(async (resolve, reject) => {
        try {
          const { token } = await login(loginParams)
          const user = parseJwt(token)
          commit('SET_TOKEN', { accessToken: token })
          commit('SET_USER', user)
          resolve()
        } catch (e) {
          reject(e)
        }
      })
    },
    async RefreshToken({ commit, state }) {
      const { accessToken, refreshToken } = await doRefreshToken()
      commit('SET_TOKEN', { accessToken, refreshToken })
    },
    // 获取用户信息
    async SetRoute({ dispatch, commit }) {
      const uInfo = await getInfo()
      const processedMenus = await dispatch('GenerateMenus', { menus: uInfo.roles.permission.menus })
      const routes = await dispatch('GenerateRoutes', { processedMenus })
      resetAsyncRoutes(routes)
      commit('SET_ROUTE_LOADED', true)
    },

    // 登出
    Logout({ commit }, { type = 'default' } = {}) {
      return new Promise((resolve, reject) => {
        // 清理permissions  -> 清理用户数据 -> 清理动态routes(好像没有对应的api，只能通过window重载？)
        commit('RESET_PERMISSION')
        commit('RESET_USER')

        const loginPage = `/user/login`

        // 注意，在beforeEach 调用next()之前，router.currentRoute获取不到正确的地址
        // 通过router登出
        if (type === 'default') {
          if (loginPage === router.currentRoute.path) {
            reject(new Error('Dispatch [Logout] in loginPage'))
            return
          }
          const to = `${loginPage}?redirect=${encodeURIComponent(router.currentRoute.fullPath)}`
          router.push(to)
          resolve()
        }
        // window重载
        if (type === 'reLaunch') {
          // 这里直接跳转登录，不要通过根路径redirect到登录，因为根路径是动态绑定的menu
          window.location.href = loginPage
          resolve()
        }
        // 其他操作
        if (type === 'noJump') {
          resolve()
        }
      })
    },
  },
}

export default user
