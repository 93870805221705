import OSS from 'ali-oss'
import { toHttps } from '@/utils/format'

const ossConfig = {
  region: 'oss-cn-hangzhou',
  accessKeyId: 'LTAI5tBfMNXPFmytgcWFDFKY',
  accessKeySecret: 'g2gUOtOBszEh2oRXvUaNvHMOqFauLG',
  bucket: 'vantai-web',
  secure: true
}
// oss -> cdn 映射域名,
const domain = ''
const ossDomain = `${ossConfig.bucket}.${ossConfig.region}.aliyuncs.com`
function transOSSDomain(path, https = true) {
  let result = path
  if (path && domain) {
    result = path.replace(ossDomain, domain)
  }
  if (https) {
    result = toHttps(result)
  }
  return result
}
function isOSSPath(path) {
  if (path) {
    if (path.includes(domain) || path.includes(ossDomain)) {
      return true
    }
  }
  return false
}
function transOSSPath(path) {
  return `https://${ossDomain}/${path}`
}
async function ossUpload(file, fileStoreAs, multipartUploadOptions) {
  let storeAs = fileStoreAs
  const suffix = file.name.match(/\.[^ .]+$/)[0]
  if (!storeAs) {
    storeAs = 'common/' + new Date().getTime() + suffix
  }
  const result = await ossClient.multipartUpload(storeAs, file, multipartUploadOptions)
  const path = transOSSDomain(transOSSPath(result.name))
  return path
}
const ossClient = new OSS(ossConfig)
export { domain, ossClient, transOSSDomain, transOSSPath, isOSSPath, ossUpload }
