import Vue from 'vue'
import Router from 'vue-router'
import { constantRoutes } from '@/routes'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)
const createRouter = () => {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  })
}
const router = createRouter()
// 重置并添加路由
export const resetAsyncRoutes = (routes) => {
  router.matcher = createRouter().matcher
  routes.forEach((route) => {
    router.addRoute(route)
  })
  // 只影响页面F5刷新以后返回
  const currentPath = router.currentRoute.fullPath
  router.onReady(() => {
    const nextPath = router.currentRoute.fullPath
    // 重置路由时：push会推入一个 currentPath('/') -> nextPath的历史，需要去除该记录；replace currentPath === nextPath，就不需要返回了
    if (currentPath === '/' && currentPath !== nextPath) {
      router.go(-1)
    }
  })
}

export default router
