<script>
export default {
  props: {
    render: {
      required: true,
      type: Function,
    },
  },
  render() {
    const node = this.render()
    return node
  },
}
</script>
