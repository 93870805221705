import { setDocumentTitle } from '@/utils/domUtil'
import { is401, doLogout } from '@/plugins/axios/configFns'

export default function (context) {
  const { app, router, store } = context
  // eslint-disable-next-line
  const { $progress, $popErrorPage } = app

  router.beforeEach(async (to, from, next) => {
    $progress.start() // start progress bar
    to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(to.meta.title)

    if (to.meta.isAuth === false) {
      next()
    } else {
      if (store.state.user.auth.accessToken) {
        /* has token */

        if (!store.state.permission.loaded) {
          try {
            // 根据用户信息生成可访问的路由表
            await store.dispatch('SetRoute')
            // addroute 会导致next丢失，必须显示添加to
            next(to)
          } catch (e) {
            console.log('[GetInfo fail]', e)
            // 先跳转，store里面router.currentRoute才能获取正确的值
            next()
            // 这里单独处理401
            if (is401(e)) {
              doLogout(e)
            } else {
              $popErrorPage({ message: e.message })
            }
          }
        } else {
          next()
        }
      } else {
        next({ path: '/user/login', query: { redirect: to.fullPath }, replace: true })
        $progress.done() // if current page is login will not trigger afterEach hook, so manually handle it
      }
    }
  })
  router.afterEach(() => {
    $progress.done() // finish progress bar
  })
}
