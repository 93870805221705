import Vue from 'vue'
import {
  ConfigProvider,
  Layout,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Tabs,
  Icon,
  Badge,
  Popover,
  Dropdown,
  Avatar,
  Breadcrumb,
  Spin,
  Menu,
  Tooltip,
  Alert,
  Tag,
  Divider,
  DatePicker,
  TimePicker,
  Upload,
  Progress,
  Pagination,
  Popconfirm,
  message,
  notification,
  TreeSelect,
  Tree,
  Transfer,
  // Drawer,
  // Skeleton,
  // Steps,
  // List,
  // Timeline,
  // Cascader,

  // Slider,
  // Empty,
  // Descriptions,
  AutoComplete,
  // Rate,
  // Calendar,
} from 'ant-design-vue'
Vue.use(ConfigProvider)
Vue.use(Layout)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Popover)
Vue.use(Dropdown)

Vue.use(Avatar)
Vue.use(Breadcrumb)

Vue.use(Spin)
Vue.use(Menu)

Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Popconfirm)

Vue.use(Pagination)
Vue.use(notification)
Vue.use(TreeSelect)
Vue.use(Tree)
Vue.use(Transfer)
// Vue.use(VueCropper)
// Vue.use(List)
// Vue.use(Steps)
// Vue.use(Drawer)
// Vue.use(Skeleton)
// Vue.use(Timeline)
// Vue.use(Cascader)

// Vue.use(Slider)
// Vue.use(Empty)
// Vue.use(Anchor)
// Vue.use(Descriptions)
Vue.use(AutoComplete)
// Vue.use(Calendar)

// Vue.use(Rate)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

const AppIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_3282386_50vorqfcwk3.js',
})
Vue.component('AppIcon', AppIcon)
