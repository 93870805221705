import enquireJs from 'enquire.js'

export const DEVICE_TYPE = {
  DESKTOP: 'desktop',
  LAPTOP: 'laptop',
  TABLET: 'tablet',
  MOBILE: 'mobile'
}

export const deviceEnquire = function(callback) {
  const matchDesktop = {
    match: () => {
      callback && callback(DEVICE_TYPE.DESKTOP)
    }
  }

  const matchLapTop = {
    match: () => {
      callback && callback(DEVICE_TYPE.LAPTOP)
    }
  }

  const matchLablet = {
    match: () => {
      callback && callback(DEVICE_TYPE.TABLET)
    }
  }

  const matchMobile = {
    match: () => {
      callback && callback(DEVICE_TYPE.MOBILE)
    }
  }
  // 目前屏幕允许屏幕最小值1200，1200-1600 顶部用户信息收缩，>1600 顶部用户信息展开
  // screen and (max-width: 1087.99px)
  enquireJs
    .register('screen and (max-width: 576px)', matchMobile)
    .register('screen and (min-width: 576px) and (max-width: 1199px)', matchLablet)
    .register('screen and (min-width: 1200px) and (max-width: 1600px)', matchLapTop)
    .register('screen and (min-width: 1600px)', matchDesktop)
}
