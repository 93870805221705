import treeUtils from '@/utils/treeUtils'
import store from '@/store'
import Vue from 'vue'

/**
 * Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action="code" , 如下：
 *    <i-button v-action="'add'" >添加用户</a-button>
 *    <a-button v-action="'delete'">删除用户</a-button>
 *    <a v-action="'edit'" @click="edit(record)">修改</a>
 */
Vue.directive('action', {
  inserted: function (el, binding, vnode) {
    let actionCode = []
    if (typeof binding.value === 'string') {
      actionCode = [binding.value]
    } else if (binding.value instanceof Array) {
      actionCode = [...binding.value]
    }
    const route = vnode.context.$route
    // 有actions 字段的才是menu
    let actions = route.meta.actions
    let cache = route
    while (!actions) {
      cache = treeUtils.findParent(store.state.permission.menus, (i) => {
        return i.path === cache.path
      })
      if (cache) {
        // actions有值则终止循环
        actions = cache.meta.actions
      } else {
        // 终止循环
        actions = []
      }
    }
    let matched = false
    for (let i = 0; i < actions.length; i++) {
      // 只会替换匹配的文本内容，如果不是简单的文本内容。
      // 如：<div><a>新</a><a>增</a></div> 则不会替换。
      if (actionCode.includes(actions[i].code)) {
        const text = el.textContent
        el.innerHTML = el.innerHTML.replace(text, actions[i].name)
        matched = true
      }
    }
    if (!matched) {
      ;(el.parentNode && el.parentNode.removeChild(el)) || (el.style.display = 'none')
    }
  },
})
