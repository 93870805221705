<template>
  <a-config-provider :locale="locale" :transformCellText="transformCellText">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'
export default {
  mixins: [AppDeviceEnquire],
  data() {
    return {
      locale: zhCN,
    }
  },
  mounted() {},
  methods: {
    transformCellText({ text }) {
      return text ?? '-'
    },
  },
}
</script>
<style lang="less"></style>
